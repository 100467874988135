<template>
  <FullScreenDialog
    v-model="localDialog"
    title="Pagamento"
    without-padding
    @input="handleFullScreenDialogChange"
    :can-close="canCloseDialog"
  >
    <template v-slot:content>
      <template v-if="cashDeskOpened">
        <v-stepper v-model="currentStep" height="100%">
          <v-stepper-header>
            <template v-for="(step, index) in steps">
              <v-stepper-step
                :key="step.name"
                :complete="currentStep > index + 1"
                :step="index + 1"
                :editable="currentStep > index + 1 && canChangeStep"
                :disabled="!canChangeStep"
                @click="handleStepClicked(index + 1)"
                edit-icon="mdi-check"
                v-ripple="false"
              >
                {{ step.title }}
                <div class="text-caption">{{ step.subtitle }}</div>
              </v-stepper-step>
              <v-divider
                :key="step.name + 'divider'"
                v-if="index != steps.length - 1"
              ></v-divider>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <template v-for="(step, index) in steps">
              <v-stepper-content
                :step="index + 1"
                :key="step.name"
                v-if="step.name == 'invoiceOrReceipt'"
                class="pa-0"
              >
                <div
                  style="max-height: 70vh; height: 800px; width: 100%; overflow: auto"
                  class="d-flex justify-center align-center flex-wrap"
                >
                  <v-card
                    class="text-h6 d-flex align-center justify-center"
                    height="200"
                    width="200"
                    @click="handleInvoiceClick()"
                    :disabled="localBill.documentType == 'receipt' ||
                      !!partialPayment"
                    :color="localBill.documentType == 'receipt' ||
                      !!partialPayment ? '#dddddd' : ''"
                  >
                    Fattura
                  </v-card>
                  <v-card
                    class="text-h6 d-flex align-center justify-center ml-2"
                    height="200"
                    width="200"
                    @click="handleReceiptClick()"
                    :disabled="localBill.documentType == 'invoice'"
                    :color="localBill.documentType == 'invoice' ? '#dddddd' : ''"
                  >
                    Scontrino
                  </v-card>
                </div>
              </v-stepper-content>
              <v-stepper-content
                :step="index + 1"
                v-else-if="step.name == 'invoiceData'"
                :key="step.name"
                class="pa-0"
              >
                <div class="d-flex justify-center mt-2">
                  <SuppliersAutocomplete
                    v-if="!customer"
                    v-model="supplier"
                    style="max-width: 800px"
                    return-object
                    append-outer-icon="mdi-domain"
                    :auto-detect-new-supplier="true"
                    label="Inserire ragione sociale..."
                    class="mr-2"
                    :disabled="!!supplier"
                  ></SuppliersAutocomplete> 
                  <CustomersAutocomplete
                    v-if="!supplier"
                    v-model="customer"
                    style="max-width: 800px"
                    return-object
                    append-outer-icon="mdi-account"
                    label="Inserire cognome..."
                    :disabled="!!customer"
                  >
                  </CustomersAutocomplete>
                </div>
                <div
                  class="
                    d-flex
                    justify-center
                    align-center
                    flex-column flex-wrap
                    py-3
                  "
                >   
                  <InvoiceDataForm
                    v-model="invoiceData"
                    :valid.sync="invoiceFormValid"
                    :bus="invoiceFormDataBus"
                  >
                  </InvoiceDataForm>
                  <div class="mt-3">
                    <v-btn
                      text
                      color="error"
                      @click="goBackToStep(currentStep - 1)"
                    >
                      <v-icon left>mdi-arrow-left</v-icon>
                      Indietro
                    </v-btn>
                    <v-btn 
                      v-if="(!!customer || !!supplier) && !!invoiceData"
                      text 
                      color="default"
                      :loading="loadingCustomerUpdate"
                      @click="updateCustomerDataInvoice"
                    >
                      <v-icon left>mdi-content-save</v-icon>
                      Salva sul cliente
                    </v-btn>
                    <v-btn
                      text
                      color="success"
                      @click="goToStep(3)"
                      :disabled="!invoiceFormValid"
                    >
                      <v-icon left>mdi-check</v-icon>
                      Conferma
                    </v-btn>
                  </div>
                </div>
              </v-stepper-content>
              <v-stepper-content
                :step="index + 1"
                :key="step.name"
                v-else-if="step.name == 'summary'"
              >
                <div
                  style="height: 70vh; width: 100%"
                  class="
                    d-flex
                    flex-column
                    justify-center
                    align-center
                    flex-wrap
                  "
                >
                  <PaymentSummary
                    ref="paymentSummary"
                    :total="totalValue"
                    :discount="discountOnTotal"
                    :provided-entities="providedEverything"
                    :item-price="handleItemPrice"
                    :local-bill="localBill"
                    :item-name="(anything) => {
                      if(!!anything.service) {
                        return anything.service.name
                      } else if (!!anything.item) {
                        return anything.item.description
                      } else {
                        return 0
                      }
                    }"
                    bar-or-saloon="bar"
                    :opened-cash-desk="openedCashDesk"
                    @confirm="goToStep(currentStep + 1)"
                    @go-back="goBackToStep(currentStep - 1)"
                    :hide-paid-amounts="false"
                    :paid-with-cash.sync="paidWithCash"
                    :paid-with-card.sync="paidWithCard"
                    :paid-with-tickets.sync="paidWithTickets"
                    :paid-with-check.sync="paidWithCheck"
                    :not-paid.sync="notPaid"
                    :total-paid.sync="totalPaid"
                    :invoice-or-receipt="invoiceOrReceipt"
                    :invoice-data="invoiceData"
                    :hide-not-paid="(invoiceOrReceipt == 'invoice' && (!customer || !supplier))
                      || (invoiceOrReceipt == 'receipt' && !localBill.customers || localBill.customers.length == 0)"
                  ></PaymentSummary>
                </div>
              </v-stepper-content>
              <v-stepper-content
                :step="index + 1"
                :key="step.name"
                v-else-if="step.name == 'payment'"
              >
                <div
                  style="height: 70vh"
                  class="
                    d-flex
                    flex-column
                    justify-center
                    align-center
                    flex-wrap
                  "
                >
                  <div class="d-flex justify-center align-center my-2">
                    <AnimatedCheck
                      class="mx-4"
                      v-model="invoicePrinted"
                    ></AnimatedCheck>
                    <div
                      class="text-h6 text-center"
                      style="width: 500px; max-width: 90vw"
                    >
                      {{ invoicePrintedMessage }}
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center my-2">
                    <AnimatedCheck
                      class="mx-4"
                      v-model="billClosed"
                    ></AnimatedCheck>
                    <div
                      class="text-h6 text-center"
                      style="width: 500px; max-width: 90vw"
                    >
                      {{ billClosedMessage }}
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center my-2">
                    <AnimatedCheck
                      class="mx-4"
                      v-model="dataRegistered"
                    ></AnimatedCheck>
                    <div
                      class="text-h6 text-center"
                      style="width: 500px; max-width: 90vw"
                    >
                      {{ dataRegisteredMessage }}
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center mt-5">
                    <v-btn
                      icon
                      @click="closeDialog"
                      :disabled="canCloseDialog"
                    ></v-btn>
                  </div>
                </div>
              </v-stepper-content>
            </template>
          </v-stepper-items>
        </v-stepper>
      </template>
      <template v-else> Non puoi procedere con la cassa chiusa </template>
    </template>
  </FullScreenDialog>
</template>

<script>
import Printer from "@/services/devices/rtPrinter";
import cashDeskService from "@/services/cashDesks/cashDesks.service.js";
import StandardDialog from "@/components/common/StandardDialog";
import FullScreenDialog from "@/components/common/FullScreenDialog";
import billService from "@/services/bills/bills.service.js";
import billBarCalculator from "@/services/bills/billBar.calculator.js";
import OperatorAvatar from "@/components/common/OperatorAvatar.vue";
import NumericKeyboardInput from "@/components/common/NumericKeyboardInput.vue";
import AnimatedCheck from "@/components/common/AnimatedCheck.vue";
import PaymentSummary from "@/components/homepage/sections/PaymentSummary.vue";
import InvoiceDataForm from "@/components/homepage/sections/InvoiceDataForm.vue";
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import CustomersAutocomplete from "@/components/common/CustomersAutocomplete.vue";
import customerService from '@/services/customers/customers.service.js'
import supplierService from "@/services/suppliers/suppliers.service.js";
import marketingEventsService from '@/services/marketingEvents/marketingEvents.service.js';
import Vue from 'vue';

export default {
  name: "CashDialogBar",
  components: {
    StandardDialog,
    FullScreenDialog,
    OperatorAvatar,
    NumericKeyboardInput,
    AnimatedCheck,
    PaymentSummary,
    SuppliersAutocomplete,
    CustomersAutocomplete,
    InvoiceDataForm,
  },
  data: function () {
    return {
      localBill: undefined,
      localDialog: this.dialog,
      currentStep: 1,
      invoiceOrReceipt: undefined,
      total: undefined,
      totalValue: undefined,
      discountOnTotal: undefined,
      openedCashDesk: undefined,
      paidWithCash: 0,
      paidWithCard: 0,
      paidWithTickets: 0,
      paidWithCheck: 0,
      notPaid: 0,
      totalPaid: undefined,
      billClosed: false,
      dataRegistered: false,
      invoicePrinted: false,
      canCloseDialog: true,
      canChangeStep: true,
      billBarCalculatorUpdateHandler: undefined,
      invoiceFormValid: false,
      typeCredit: false,
      invoiceData: {
        companyNameOrLastname: "",
        firstname: "",
        address: "",
        cap: "",
        city: "",
        iva: "",
        fiscalCode: "",
        mail: "",
        destinataryCode: "",
        paymentConditionId: 0,
        paymentTermId: 0,
        paymentTypeNatureExemptionId: 0,
      },
      supplier: undefined,
      customer: undefined,
      invoiceFormDataBus: new Vue(),
      loadingCustomerUpdate: false,
      savedInfo: false
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    goBack: {
      type: Boolean,
      default: false,
    },
    goBackTo: {},
    billId: {},
    bill: {},
    customerCard: {},
    tableId: {
      type: Number,
      default: undefined,
    },
    discountBar: {
      type: Object,
      default: undefined,
    },
    partialPayment: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: undefined,
    },
  },
  mounted: function () {
    if (!!this.bill) {
      this.localBill = { ...this.bill };
      this.setCalculator();
    } else if (!!this.billId) {
      billService.get({ id: this.billId }).then((bill) => {
        this.localBill = bill;
        this.setCalculator();
      });
    }

    this.getOpenedCashDesk();
  },
  methods: {
    updateCustomerDataInvoice() {
      if ((!!this.customer || !!this.supplier) && !!this.invoiceData) {
        let dataInvoice = {...this.invoiceData}

        if (!!this.customer) {
          dataInvoice.lastname = dataInvoice.companyNameOrLastname
          delete dataInvoice.companyNameOrLastname

          this.loadingCustomerUpdate = true
          customerService.update({
            id: this.customer.id,
            dataInvoice: dataInvoice
          }).then((results) => {
            this.loadingCustomerUpdate = false
            this.savedInfo = true
            this.$delegates.snackbar('Salvataggio avvenuto correttamente')
          })
        } else if (!!this.supplier) {
          let localSupplier = {
            id: this.supplier.id,
            businessName: dataInvoice.companyNameOrLastname,
            city: dataInvoice.city,
            address: dataInvoice.address,
            email: dataInvoice.mail,
            VATNumber: dataInvoice.vatNumber
          }

          delete dataInvoice.companyNameOrLastname
          delete dataInvoice.city
          delete dataInvoice.address
          delete dataInvoice.mail
          delete dataInvoice.vatNumber

          localSupplier.dataInvoice = {
            id: this.supplier.dataInvoice.id,
            ...dataInvoice
          }

          this.loadingCustomerUpdate = true
          supplierService.update(localSupplier).then((results) => {
            this.loadingCustomerUpdate = false
            this.savedInfo = true
            this.$delegates.snackbar('Salvataggio avvenuto correttamente')
          })
        }
      } else {
        this.$delegates.snackbar('Salvataggio non riuscito')
      }
    },
    closeDialog() {
      this.$emit("update:dialog", false);
      let goBack;
      if (!!this.goBack) {
        goBack = () => {
          this.$router.go(-1);
        };
      } else if (!!this.goBackTo) {
        let goBackTo = { ...this.goBackTo };
        if (
          this.billClosed &&
          this.dataRegistered &&
          this.invoicePrinted &&
          !!goBackTo.params
        )
          goBackTo.params.selectedBill = undefined;
        goBack = () => {
          this.$router.push(this.goBackTo);
        };
      }
      setTimeout(goBack.bind(this), 200);
    },
    getOpenedCashDesk() {
      cashDeskService.getOpened().then((openedCashDesk) => {
        this.openedCashDesk = openedCashDesk;
      });
    },
    setCalculator() {
      let promises = [];

      if (!!this.localBill) {
        promises.push(billBarCalculator.setBill(this.localBill));
      }
      if (!!this.customerCard) {
        billBarCalculator.setCustomerCard(this.customerCard);
      }

      Promise.all(promises).then(() => {
        this.total = billBarCalculator.subTotalFormatted;
        this.totalValue = billBarCalculator.subTotal;
        this.discountOnTotal = Number(billBarCalculator.discount);
      });
    },
    handleFullScreenDialogChange(newVal) {
      if (!newVal) {
        this.closeDialog();
      }
    },
    startFromScratch() {
      this.invoiceOrReceipt = undefined;
      this.currentStep = 1;
    },
    setInvoiceOrReceipt(type) {
      this.invoiceOrReceipt = type;
    },
    goToStep(number) {
      const step = this.steps[number - 1];
      if (step.name == "summary") {
        setTimeout(() => {
          this.$refs.paymentSummary[0].calculateScrollReceipt();
        }, 200);
      } else if (step.name == "payment") {
        setTimeout(() => {
          this.pay();
        }, 1000);
      }
      this.currentStep = number;
    },
    goBackToStep(number) {
      const step = this.steps[number - 1];
      if (step.name == "invoiceOrReceipt") {
        this.invoiceOrReceipt = undefined;
      }
      this.goToStep(number);
    },
    handleStepClicked(number) {
      if (this.currentStep == number || !this.canChangeStep) {
        return;
      } else if (this.currentStep < number) {
        this.$delegates.snackbar("Confermare prima di avanzare");
      } else {
        this.goBackToStep(number);
      }
    },
    handleInvoiceClick() {
      this.setInvoiceOrReceipt("invoice");
      this.$nextTick(() => {
        this.goToStep(2);
      });
    },
    handleReceiptClick() {
      this.setInvoiceOrReceipt("receipt");
      this.$nextTick(() => {
        this.goToStep(2);
      });
    },
    handleItemPrice(anything) {
      if(!!anything.service) {
        if (!!anything.editPriceType && !!anything.editPriceValue)
          return billBarCalculator.calculateEditedPrice(anything)
        else
          return anything.service.price
      } else if(!!anything.item) {
        if (!!anything.editPriceType && !!anything.editPriceValue)
          return billBarCalculator.calculateEditedItemPrice(anything)
        else
          return anything.item.priceSelling
      } else {
        return 0
      }
    },
    calculateEntities() {
      const services = this.providedServices.map(billService => {
        let taxForBar = billService.tax !== undefined && billService.tax !== null ? billService.tax : undefined
        if (taxForBar === undefined || taxForBar === null) taxForBar = '22'

        let object = {
          name: billService.service.name.replace("&", "e"),
          price: Number(billService.service.price),
          quantity: billService.quantity,
          discount: billService.discount != '0.00' ? billService.discount : undefined,
          discountDescription: billService.discount != '0.00' ? billService.discountDescription : undefined,
          tax: taxForBar + '%',
          department: "bar",
          ghost: billService.ghost
        }

        if (!!billService.editPriceType && !!billService.editPriceValue) {
          object.price = billBarCalculator.calculateEditedPrice(billService)
          //if (!!object.discount)
            //object.discount = billBarCalculator.calculateEditedPrice(billService, object.discount)
        }

        return object
      }).filter(el => !!el.quantity && !el.ghost)

      const items = this.providedItems.map(billItem => {
        let taxForBar = billItem.tax !== undefined && billItem.tax !== null ? billItem.tax : undefined
        if (taxForBar === undefined || taxForBar === null) taxForBar = '22'

        let object = {
          name: billItem.item.description.replace("&", "e"),
          price: Number(billItem.item.priceSelling),
          quantity: billItem.quantity,
          discount: billItem.discount != '0.00' ? billItem.discount : undefined,
          additionalDescription: "",
          discountDescription: billItem.discount != 0 ? billItem.discountDescription : undefined,
          tax: taxForBar + '%',
          department: "bar",
          ghost: billItem.ghost
        }

        if (!!billItem.editPriceType && !!billItem.editPriceValue) {
          object.price = billBarCalculator.calculateEditedItemPrice(billItem)
          //if (!!object.discount)
            //object.discount = billBarCalculator.calculateEditedItemPrice(billItem, object.discount)
        }

        return object
      }).filter(el => !!el.quantity && !el.ghost)

      return {
        everything: [
          ...services,
          ...items
        ],
        items,
        services
      }

    },
    async pay() {
      this.billClosed = false;
      this.dataRegistered = false;
      this.invoicePrinted = false;
      this.canCloseDialog = false;
      this.canChangeStep = false;
      let rtResponse
      let transaction

      const { everything, items, services } = this.calculateEntities()

      if(Number(this.paidWithCash) + (Number(this.paidWithCard) || 0) + (Number(this.paidWithTickets) || 0) + (Number(this.paidWithCheck) || 0) > Number(this.totalValue)) {
        this.paidWithCash = Math.max(Number(this.totalValue) - (Number(this.paidWithCard) || 0) - (Number(this.paidWithTickets) || 0) - (Number(this.paidWithCheck) || 0), 0)
      }

      if(Number(this.paidWithCard) + (Number(this.paidWithTickets) || 0) + (Number(this.paidWithCheck) || 0) > Number(this.totalValue) - (Number(this.paidWithCash) || 0)) {
        this.paidWithCard = Math.max(Number(this.totalValue) - (Number(this.paidWithTickets) || 0) - (Number(this.paidWithCheck) || 0), 0)
      }

      if(Number(this.paidWithTickets) + (Number(this.paidWithCheck) || 0) > Number(this.totalValue) - (Number(this.paidWithCash) || 0) - (Number(this.paidWithCard) || 0)) {
        this.paidWithTickets = Math.max(Number(this.totalValue) - (Number(this.paidWithCheck) || 0), 0)
      }

      if(Number(this.paidWithCheck) > Number(this.totalValue) - (Number(this.paidWithCash) || 0) - (Number(this.paidWithCard) || 0) - (Number(this.paidWithTickets) || 0)) {
        this.paidWithCheck = Number(this.totalValue)
      }

      let paymentTypes = []
      try {
        if(!!this.paidWithCash) {
          paymentTypes.push({
            amount: this.paidWithCash,
            type: 'cash'
          })
        }

        if(!!this.paidWithCard) {
          paymentTypes.push({
            amount: this.paidWithCard,
            type: 'card'
          })
        }

        if(!!this.notPaid) {
          this.typeCredit = true
          paymentTypes.push({
            amount: this.notPaid,
            type: 'credit'
          })
        }

        if(!!this.paidWithTickets) {
          paymentTypes.push({
            amount: this.paidWithTickets,
            type: 'ticket'
          })
        }

        if(!!this.paidWithCheck) {
          paymentTypes.push({
            amount: this.paidWithCheck,
            type: 'cheque'
          })
        }

        if(paymentTypes.length == 0) {
          paymentTypes.push({
            amount: 0,
            type: 'cash'
          })
        }

        try {
          let printerStatus = await Printer.getStatus()
        } catch (exception) {
          // KEEP LOG
          console.log(exception)

          this.$delegates.snackbar('Errore durante la comunicazione con la stampante')
          this.canCloseDialog = true
          this.billClosed = false
          return
        }

        this.dataRegistered = true;

        try {
          if (this.invoiceOrReceipt == "receipt" && everything.length > 0) {
            if (this.typeCredit) {
              if (services.length > 0) {
                rtResponse = await Printer.printSummary({
                  amount: billBarCalculator.servicesTotal,
                  items: services,
                  additionalRows: [
                    "Non riscosso: " + billBarCalculator.servicesTotal
                  ],
                }, false, "bar receipt credit services")
              }

              if (items.length > 0) {
                rtResponse = await Printer.printFiscalReceipt({
                  paymentTypes: paymentTypes,
                  amount: billBarCalculator.itemsTotal,
                  items: items,
                  discounts: undefined,
                  additionalRows: []
                }, "bar receipt credit")
              }
            } else {
              rtResponse = await Printer.printFiscalReceipt({
                paymentTypes: paymentTypes,
                items: everything,
                discounts: !!this.discountOnTotal ? [
                  {
                    description: 'Sconto Totale',
                    amount: this.discountOnTotal,
                    tax: everything[0].tax
                  },
                ] : undefined
              }, "bar receipt notCredit"); 
            }
          } else if (this.invoiceOrReceipt == "invoice") {
            // copia cliente
            rtResponse = await Printer.printSummary({
              amount: billBarCalculator.toBePaid,
              invoiceData: this.invoiceData,
              items: everything
            }, true, "bar invoice cliente")

            // copia salone
            rtResponse = await Printer.printSummary({
              amount: billBarCalculator.toBePaid,
              invoiceData: this.invoiceData,
              items: everything
            }, true, "bar invoice salone")
          }
        } catch (exception) {
          // KEEP LOG
          console.log(exception)

          this.$delegates.snackbar('Errore durante la stampa dello scontrino')
          this.canCloseDialog = true
          this.billClosed = false
          return
        }

        try {
          transaction = await this.closeBill({
            rtResponse
          })
        } catch (exception) {
          // KEEP LOG
          console.log(exception)

          this.$delegates.snackbar('Errore durante la chiusura del conto')
          this.canCloseDialog = true
          this.billClosed = false
        }
      } catch(exception) {
        // logging the exception for debug purpose
        // KEEP LOG
        console.log(exception)

        if (String(exception).includes("URL") || String(exception).includes("protocol")) {
          this.$delegates.snackbar('Stampante non collegata / Indirizzo stampante errato', 0)
          this.canCloseDialog = true
          return
        }

        // if something goes wrong manually set the success to false
        rtResponse = {
          attributes: {
            success: 'false'
          }
        }
      }
 

      if(!!rtResponse && !!rtResponse.attributes && rtResponse.attributes.success == 'false') {
        this.$delegates.snackbar('Errore durante la stampa dello scontrino')
        this.canCloseDialog = true
        return 
      }

      if (!!transaction) {
        if (!!rtResponse) {
          let documentNumberFixed = undefined
          if (!!rtResponse && !!rtResponse.addInfo) {
            rtResponse.addInfo.zRepNumber = '' + rtResponse.addInfo.zRepNumber
            rtResponse.addInfo.fiscalReceiptNumber = '' + rtResponse.addInfo.fiscalReceiptNumber
            while (rtResponse.addInfo.zRepNumber.length < 4) {
              rtResponse.addInfo.zRepNumber = '0' + rtResponse.addInfo.zRepNumber
            }
            while (rtResponse.addInfo.fiscalReceiptNumber.length < 4) {
              rtResponse.addInfo.fiscalReceiptNumber = '0' + rtResponse.addInfo.fiscalReceiptNumber
            }
            if (!!rtResponse.addInfo.zRepNumber && rtResponse.addInfo.zRepNumber != 'undefined' 
              && !!rtResponse.addInfo.fiscalReceiptNumber && rtResponse.addInfo.fiscalReceiptNumber != 'undefined') {
                documentNumberFixed = rtResponse.addInfo.zRepNumber + '-' + rtResponse.addInfo.fiscalReceiptNumber
            }

            billService.updateDocumentNumber(transaction.id, documentNumberFixed)
          }
        }

        let receiptModels = []

        if (!!this.customerId) {
          try {
            let marketingEventsOfTheDay = await marketingEventsService.getTodayEvents({
              tool: 'receipt',
              status: 'approved',
              actionHasStarted: true,
              customerId: this.customerId
              //transactionId: transaction.id
            })

            let totalPaid = paymentTypes.reduce((acc, item) => {
              return acc + Number(item.amount)
            }, 0)


            marketingEventsOfTheDay.forEach((event) => {
              let ctrl = false
              if (!!event.actions.recipients.sendByFinancialService) {
                event.actions.recipients.sendByFinancialService.forEach((recipient) => {
                  switch (recipient) {
                    case '50-100':
                      if (totalPaid >= 50 && totalPaid <= 100)
                        ctrl = true
                      break;
                    case '100-200':
                      if (totalPaid >= 100 && totalPaid <= 200)
                        ctrl = true
                      break;
                    case '200-300':
                      if (totalPaid >= 200 && totalPaid <= 300)
                        ctrl = true
                      break;
                    case '300+':
                      if (totalPaid >= 300)
                        ctrl = true
                      break;
                  }
                })
              } else
                ctrl = true

              if (ctrl)
                receiptModels.push(event.actions.receiptModel)
            })

            if (receiptModels.length > 0)
              await Printer.marketingReport(receiptModels)
          } catch (exception) {
            console.log(exception)
          }
        }
      }

      this.invoicePrinted = true;

      this.canCloseDialog = true;
      this.billClosed = true
      setTimeout(this.closeDialog, 2000)
    },

    async closeBill({ rtResponse }) {
      //set new prices and discount prices for services
      this.localBill.billServices.sort((a, b) => {
        if (!!a.service && !!b.service) {
          return Number(b.service.price) - Number(a.service.price)
        } else {
          return 0
        }
      })

      for (let i = 0; i < this.localBill.billServices.length; i++) {
        if (!!this.localBill.billServices[i].editPriceType && !!this.localBill.billServices[i].editPriceValue) {
          this.localBill.billServices[i].price = billBarCalculator.calculateEditedPrice(this.localBill.billServices[i])
          if (!!this.localBill.billServices[i].discount)
          this.localBill.billServices[i].discount = billBarCalculator.calculateEditedPrice(this.localBill.billServices[i], this.localBill.billServices[i].discount)
        }
      }

      this.localBill.billItems.sort((a, b) => {
        if (!!a.item && !!b.item) {
          return Number(b.item.priceSelling) - Number(a.item.priceSelling)
        } else {
          return 0
        }
      })
      //set new prices and discount prices for items
      for (let i = 0; i < this.localBill.billItems.length; i++) {
        if (!!this.localBill.billItems[i].editPriceType && !!this.localBill.billItems[i].editPriceValue) {
          this.localBill.billItems[i].price = billBarCalculator.calculateEditedItemPrice(this.localBill.billItems[i])
          if (!!this.localBill.billItems[i].discount)
          this.localBill.billItems[i].discount = billBarCalculator.calculateEditedItemPrice(this.localBill.billItems[i], this.localBill.billItems[i].discount)
        }
      }

      let transaction
      if (this.invoiceOrReceipt == "receipt") {
        if (this.typeCredit == true) {
          for (let i = 0; i < this.localBill.billServices.length; i++) {
            this.localBill.billServices[i].status = "notPaid"
          }
          for (let k = 0; k < this.localBill.billItems.length; k++) {
            this.localBill.billItems[k].status = "notPaid"
          }
        }

        let documentNumberFixed = undefined
        if (!!rtResponse && !!rtResponse.addInfo) {
          rtResponse.addInfo.zRepNumber = '' + rtResponse.addInfo.zRepNumber
          rtResponse.addInfo.fiscalReceiptNumber = '' + rtResponse.addInfo.fiscalReceiptNumber
          while (rtResponse.addInfo.zRepNumber.length < 4) {
            rtResponse.addInfo.zRepNumber = '0' + rtResponse.addInfo.zRepNumber
          }
          while (rtResponse.addInfo.fiscalReceiptNumber.length < 4) {
            rtResponse.addInfo.fiscalReceiptNumber = '0' + rtResponse.addInfo.fiscalReceiptNumber
          }
          if (!!rtResponse.addInfo.zRepNumber && rtResponse.addInfo.zRepNumber != 'undefined' 
              && !!rtResponse.addInfo.fiscalReceiptNumber && rtResponse.addInfo.fiscalReceiptNumber != 'undefined') {
                documentNumberFixed = rtResponse.addInfo.zRepNumber + '-' + rtResponse.addInfo.fiscalReceiptNumber
            }
        }

        let payingFunction
        !!this.partialPayment ? payingFunction = 'payPartial' : payingFunction = 'close'

        transaction = await billService[payingFunction](
          this.localBill,
          this.openedCashDesk,
          this.invoiceOrReceipt,
          {
            cashPaid: this.paidWithCash,
            cardPaid: this.paidWithCard,
            checkPaid: this.paidWithCheck,
            otherPaid: this.paidWithTickets,
            notPaid: this.notPaid,
            documentNumber: !!documentNumberFixed ? documentNumberFixed : undefined,
            billEntities: this.providedEverything,
            cashDeskLog: this.openedCashDesk.cashDeskLog.id,
            discountBarTotal: this.discountOnTotal,
            discountBar: this.discountBar,
          },
          this.tableId
        )
      } else if (this.invoiceOrReceipt == "invoice") {
        if(!!this.openedCashDesk.operator.id)
          this.invoiceData.operatorId = this.openedCashDesk.operator.id

        let invoiceData = {
          ...this.invoiceData,
          lastname: this.invoiceData.companyNameOrLastname,
          amount: billBarCalculator.toBePaid,
          status: 'pending',
          description: 'Generata automaticamente dalla cassa',
        }

        delete invoiceData.companyNameOrLastname

        transaction = await billService.close({
            ...this.localBill,
            invoiceData: invoiceData,
          },
          this.openedCashDesk,
          this.invoiceOrReceipt,
          {
            cashPaid: this.paidWithCash,
            cardPaid: this.paidWithCard,
            checkPaid: this.paidWithCheck,
            otherPaid: this.paidWithTickets,
            notPaid: this.notPaid,
            cashDeskLog: this.openedCashDesk.cashDeskLog.id,
            discountBarTotal: this.discountOnTotal,
            discountBar: this.discountBar,
          },
          this.tableId
        )
      }

      return transaction
    },
    async handleCustomerInvoiceChange(){
      if(!this.customer) {
        this.invoiceData.companyNameOrLastname = ''
        this.invoiceData.firstname = ''
        this.invoiceData.address = ''
        this.invoiceData.cap = ''
        this.invoiceData.city = ''
        this.invoiceData.province = ''
        this.invoiceData.vatNumber = ''
        this.invoiceData.fiscalCode = ''
        this.invoiceData.mail = ''
        this.invoiceData.destinataryCode = ''
        this.invoiceFormDataBus.$emit('invoice-update-parent-child',this.invoiceData);
        return
      }

      let customer = await customerService.get(this.customer.id)

      if(!!customer.dataInvoice){
        this.invoiceData.companyNameOrLastname = customer.dataInvoice.lastname
        this.invoiceData.firstname = customer.dataInvoice.firstname
        this.invoiceData.address = customer.dataInvoice.address
        this.invoiceData.cap = customer.dataInvoice.cap
        this.invoiceData.city = customer.dataInvoice.city
        this.invoiceData.province = customer.dataInvoice.province
        this.invoiceData.vatNumber = customer.dataInvoice.vatNumber
        this.invoiceData.fiscalCode = customer.dataInvoice.fiscalCode
        this.invoiceData.mail = customer.dataInvoice.mail
        this.invoiceData.destinataryCode = customer.dataInvoice.destinataryCode
        this.invoiceFormDataBus.$emit('invoice-update-parent-child',this.invoiceData);
      }
      else if(!customer.dataInvoice){
        this.invoiceData.companyNameOrLastname = ''
        this.invoiceData.firstname = ''
        this.invoiceData.address = ''
        this.invoiceData.cap = ''
        this.invoiceData.city = ''
        this.invoiceData.province = ''
        this.invoiceData.vatNumber = ''
        this.invoiceData.fiscalCode = ''
        this.invoiceData.mail = ''
        this.invoiceData.destinataryCode = ''
        this.invoiceFormDataBus.$emit('invoice-update-parent-child',this.invoiceData);
      }
    }
  },
  watch: {
    dialog(newVal) {
      this.localDialog = newVal;
      if (newVal) {
        this.startFromScratch();
      }
    },
    supplier(newVal) {      
      //already company present case
      if(!!this.supplier && !!this.supplier.dataInvoice){
        this.invoiceData.companyNameOrLastname = this.supplier.businessName
        this.invoiceData.firstname = this.supplier.dataInvoice.firstname
        this.invoiceData.address = this.supplier.address
        this.invoiceData.cap = this.supplier.dataInvoice.cap
        this.invoiceData.city = this.supplier.city
        this.invoiceData.province = this.supplier.dataInvoice.province
        this.invoiceData.vatNumber = this.supplier.VATNumber
        this.invoiceData.fiscalCode = this.supplier.fiscalCode
        this.invoiceData.mail = this.supplier.email
        this.invoiceData.destinataryCode = this.supplier.dataInvoice.destinataryCode
        this.invoiceFormDataBus.$emit('invoice-update-parent-child',this.invoiceData);
      }
      //new company case
      else if(!!this.supplier){
        this.invoiceData.companyNameOrLastname = this.supplier.businessName
        this.invoiceData.address = this.supplier.address
        this.invoiceData.cap = this.supplier.cap
        this.invoiceData.city = this.supplier.city
        this.invoiceData.province = this.supplier.province
        this.invoiceData.vatNumber = this.supplier.VATNumber
        this.invoiceData.fiscalCode = this.supplier.fiscalCode
        this.invoiceData.mail = this.supplier.email
        this.invoiceData.destinataryCode = this.supplier.destinataryCode
        this.invoiceFormDataBus.$emit('invoice-update-parent-child',this.invoiceData);
      }
      else if(!this.supplier){
        this.invoiceData.companyNameOrLastname = ''
        this.invoiceData.address = ''
        this.invoiceData.cap = ''
        this.invoiceData.city = ''
        this.invoiceData.province = ''
        this.invoiceData.vatNumber = ''
        this.invoiceData.fiscalCode = ''
        this.invoiceData.mail = ''
        this.invoiceData.destinataryCode = ''
        this.invoiceFormDataBus.$emit('invoice-update-parent-child',this.invoiceData);
      }
    },
    customer(newVal){
      this.handleCustomerInvoiceChange()
    }
  },
  computed: {
    steps() {
      let steps = [];

      if (!this.invoiceOrReceipt) {
        steps.push({
          title: "Scontrino o Fattura",
          name: "invoiceOrReceipt",
        });

        steps.push({
          title: "Dati Fatturazione",
          name: "invoiceData",
        });
      } else if (this.invoiceOrReceipt == "invoice") {
        steps.push({
          title: "Fattura",
          name: "invoiceOrReceipt",
        });

        steps.push({
          title: "Dati Fatturazione",
          name: "invoiceData",
        });
      } else if (this.invoiceOrReceipt == "receipt") {
        steps.push({
          title: "Scontrino",
          name: "invoiceOrReceipt",
        });
      }

      steps.push({
        title: "Riepilogo",
        name: "summary",
      });

      steps.push({
        title: "Pagamento",
        name: "payment",
      });

      return steps;
    },
    providedServices() {
      if (!this.localBill) {
        return [];
      } else {
        return this.localBill.billServices;
      }
    },
    providedItems() {
      if (!this.localBill) {
        return [];
      } else {
        return this.localBill.billItems;
      }
    },
    providedEverything() {
      if (!this.localBill) {
        return []
      } else {
        return [
          ...this.providedServices,
          ...this.providedItems
        ]
      }
    },
    cashDeskOpened() {
      return !!this.openedCashDesk;
    },
    billClosedMessage() {
      return this.billClosed
        ? "Conto chiuso correttamente"
        : "Chiusura del conto ...";
    },
    dataRegisteredMessage() {
      return this.dataRegistered
        ? "Dati registrati correttamente"
        : "Registrazione dei dati ...";
    },
    invoicePrintedMessage() {
      return this.invoicePrinted
        ? "Scontrino stampato correttamente"
        : "Stampa dello scontrino ...";
    },
  },
};
</script>

<style>
</style>